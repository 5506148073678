// Import Library
import React, { useContext, useEffect, Fragment } from "react";

// import context
import { Context as UserContext } from '../dataStore/userAccessContext';

// Import Component
import HeaderTwo from '../component/layout/headertwo';
import BannerTwo from '../component/section/bannertwo';
import AboutTwo from '../component/section/abouttwo';
import AboutThree from '../component/section/aboutthree';
import Top from '../component/section/top';
import City from '../component/section/city';
import Map from "../component/section/map";
import Footer from '../component/layout/footer';
import Loading from '../component/loading/loading';
import Welcome from '../component/welcome/welcome';

// Import Animation
import animation from "../assets/animation.json";

const HomeTwo = () => {

    const { state, setContextGeolocation, setContextWelcome } = useContext(UserContext);
    const { geolocation, showWelcome } = state;

    useEffect(() => {
        if (!geolocation.data && geolocation.loading) {
            setContextGeolocation();
        }
    }, [geolocation.data, geolocation.loading])

    useEffect(() => {
        if (showWelcome) {
            setContextWelcome(true);
        }
    }, [])

    return (
        <Fragment>
            {showWelcome && <Welcome />}
            {geolocation.loading || !geolocation.data || !geolocation.data.country_code3 ? (
                <Loading animation={animation} />
            ) : (
                <Fragment>
                    <HeaderTwo />
                    <BannerTwo />
                    <AboutTwo countryCode={geolocation.data.country_code3} country={geolocation.data.country} />
                    <Top countryCode={geolocation.data.country_code3} />
                    <City countryCode={geolocation.data.country_code3} country={geolocation.data.country} />
                    <Map countryCode={geolocation.data.country_code3} />
                    <AboutThree />
                    <Footer country={geolocation.data.country} />
                </Fragment>
            )}
        </Fragment>
    )
}

export default HomeTwo