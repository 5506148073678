// Import Library
import { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Import Component
import Loading from '../component/loading/loading';
import CardProfile from "../component/section/cardprofile";

// Import Web Api
import posterServices from '../dataservices/posterServices'

// Import Animation
import animation from "../assets/animation.json";

const CommunityPage = () => {

    const location = useLocation();
    const { searchFilter } = location.state || {};
    const [loading, setLoading] = useState(true);
    const [profileFound, setProfileFound] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(0);

    const fetchStartValues = async () => {
        setLoading(true)
        const getSearchFilter = await posterServices.getSearchFilter(searchFilter.nationality, searchFilter.category, searchFilter.height, searchFilter.ageStart, searchFilter.ageEnd, pageNumber, pageSize);
        setLoading(false)
        if (getSearchFilter && getSearchFilter.succeeded) {
            setProfileFound(getSearchFilter.data)
            setPageNumber(getSearchFilter.pageNumber)
            setPageSize(getSearchFilter.pageSize)
            setTotalPages(getSearchFilter.totalPages)
        }
    }

    useEffect(() => {
        fetchStartValues();
    }, [])

    return (
        <Fragment>
            {loading && !profileFound ? (
                <Loading animation={animation} />
            ) : (
                <CardProfile profiles={profileFound} setPageNumber={setPageNumber} pageNumber={pageNumber} totalPages={totalPages} />
            )}
        </Fragment >
    );
}

export default CommunityPage;