// Import Library
import { useEffect, useState, Fragment } from "react";
import { useLocation } from "react-router-dom";

// Import Component
import Loading from '../component/loading/loading';
import CardProfile from "../component/section/cardprofile";

// Import Web Api
import posterServices from '../dataservices/posterServices'

// Import Animation
import animation from "../assets/animation.json";

const ActiveGroup = () => {

    const location = useLocation();
    const { countryCode, geographicLocation2Code } = location.state || {};
    const [loading, setLoading] = useState(true);
    const [profilesByCity, setProfilesByCity] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(0);

    const fetchStartValues = async () => {
        setLoading(true)
        const getCity = await posterServices.getCity(countryCode, geographicLocation2Code, pageNumber, pageSize);
        setLoading(false)
        if (getCity && getCity.succeeded) {
            setProfilesByCity(getCity.data)
            setPageNumber(getCity.pageNumber)
            setPageSize(getCity.pageSize)
            setTotalPages(getCity.totalPages)
        }
    }

    useEffect(() => {
        fetchStartValues()
    }, [pageNumber])

    return (
        <Fragment>
            {loading && !profilesByCity ? (
                <Loading animation={animation} />
            ) : (
                <CardProfile profiles={profilesByCity} setPageNumber={setPageNumber} pageNumber={pageNumber} totalPages={totalPages} />
            )}
        </Fragment>
    )
}

export default ActiveGroup