import http from "../comunication/http-common";

const getTop = async (codeCountry) => {
    const url = process.env.REACT_APP_URL_GATEWAY + "/publisher/top";
    return await http.get(url, {
        headers: {
            "Accept-Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        params: {
            codeCountry: codeCountry
        }
    })
        .then((result) => result.data)
        .catch((error) => {
            return { "code": error.response?.data?.type || error.name || "UnknownErrorCode", "message": error.response?.data?.title || error.message || "UnknownErrorMessage", "status": error.response?.status }
        });
}

const getCities = async (codeCountry) => {
    const url = process.env.REACT_APP_URL_GATEWAY + "/publisher/cities";
    return await http.get(url, {
        headers: {
            "Accept-Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        params: {
            codeCountry: codeCountry
        }
    })
        .then((result) => result.data)
        .catch((error) => {
            return { "code": error.response?.data?.type || error.name || "UnknownErrorCode", "message": error.response?.data?.title || error.message || "UnknownErrorMessage", "status": error.response?.status }
        });
}

const getCity = async (countryCode, geographicLocation2Code, pageNumber, pageSize) => {
    const url = process.env.REACT_APP_URL_GATEWAY + "/publisher/city";
    return await http.get(url, {
        headers: {
            "Accept-Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        params: {
            countryCode: countryCode,
            geographicLocation2Code: geographicLocation2Code,
            pageNumber: pageNumber,
            pageSize: pageSize
        }
    })
        .then((result) => result.data)
        .catch((error) => {
            return { "code": error.response?.data?.type || error.name || "UnknownErrorCode", "message": error.response?.data?.title || error.message || "UnknownErrorMessage", "status": error.response?.status }
        });
}

const getCountries = async () => {
    const url = process.env.REACT_APP_URL_GATEWAY + "/publisher/countries";
    return await http.get(url, {
        headers: {
            "Accept-Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
    })
        .then((result) => result.data)
        .catch((error) => {
            return { "code": error.response?.data?.type || error.name || "UnknownErrorCode", "message": error.response?.data?.title || error.message || "UnknownErrorMessage", "status": error.response?.status }
        });
}

const getCountry = async (codeCountry) => {
    const url = process.env.REACT_APP_URL_GATEWAY + "/publisher/country";
    return await http.get(url, {
        headers: {
            "Accept-Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        params: {
            codeCountry: codeCountry
        }
    })
        .then((result) => result.data)
        .catch((error) => {
            return { "code": error.response?.data?.type || error.name || "UnknownErrorCode", "message": error.response?.data?.title || error.message || "UnknownErrorMessage", "status": error.response?.status }
        });
}

const getLoadingFilter = async (codeCountry) => {
    const url = process.env.REACT_APP_URL_GATEWAY + "/publisher/loading-filter";
    return await http.get(url, {
        headers: {
            UserTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        params: {
            codeCountry: codeCountry
        }
    })
        .then((result) => result.data)
        .catch((error) => {
            return { "code": error.response?.data?.type || error.name || "UnknownErrorCode", "message": error.response?.data?.title || error.message || "UnknownErrorMessage", "status": error.response?.status }
        });
}

const getSearchFilter = async (codeNationality, codeCategory, codeHeight, minAge, maxAge, pageNumber, pageSize) => {
    const url = process.env.REACT_APP_URL_GATEWAY + "/publisher/search-filter";
    return await http.get(url, {
        headers: {
            "Accept-Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        params: {
            codeNationality: codeNationality,
            codeCategory: codeCategory,
            codeHeight: codeHeight,
            minAge: minAge,
            maxAge: maxAge,
            pageNumber: pageNumber,
            pageSize: pageSize
        }
    })
        .then((result) => result.data)
        .catch((error) => {
            return { "code": error.response?.data?.type || error.name || "UnknownErrorCode", "message": error.response?.data?.title || error.message || "UnknownErrorMessage", "status": error.response?.status }
        });
}

const getCoordinates = async (codeCountry) => {
    const url = process.env.REACT_APP_URL_GATEWAY + "/publisher/coordinates";
    return await http.get(url, {
        headers: {
            "Accept-Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        params: {
            codeCountry: codeCountry,
        }
    })
        .then((result) => result.data)
        .catch((error) => {
            return { "code": error.response?.data?.type || error.name || "UnknownErrorCode", "message": error.response?.data?.title || error.message || "UnknownErrorMessage", "status": error.response?.status }
        });
}

export default { getTop, getCities, getCity, getCountries, getCountry, getLoadingFilter, getSearchFilter, getCoordinates }