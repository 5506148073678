// Import Context
import createAppContext from "./createAppContext";

// Import Utils
import aType from "../utils/ActionTypes";

// Import Reducer
import userReducer from "./reducer";

// Import Web Api
import externalServices from "../dataservices/externalServices";
import posterServices from "../dataservices/posterServices";

// User Actions
const setContextGeolocation = (dispatch) => async () => {
    try {
        const response = await externalServices.getGeolocation();
        const geoLocationInfo = {
            country_code3: response.country_code3,
            country: response.country
        }
        dispatch({ type: aType.SET_GEOLOCATION, payload: { data: geoLocationInfo, loading: false } })
    } catch (error) {
        dispatch({ type: aType.ERROR, payload: { loading: false, error: error } });
    }
}

const setContextCountry = (dispatch) => async (code, nameSpanish) => {
    dispatch({ type: aType.SET_GEOLOCATION, payload: { data: null, loading: true, error: null } })
    const geoLocationInfo = {
        country_code3: code,
        country: nameSpanish
    }
    dispatch({ type: aType.SET_GEOLOCATION, payload: { data: geoLocationInfo, loading: false } })
}

const setContextWelcome = (dispatch) => async (showWelcome) => {
    dispatch({ type: aType.SET_WELCOME, payload: showWelcome })
}

const setContextLoadingFilter = (dispatch) => async (countryCode) => {
    try {
        const response = await posterServices.getLoadingFilter(countryCode);
        dispatch({ type: aType.SET_LOADING_FILTER, payload: { data: response, loading: false } })
    } catch (error) {
        dispatch({ type: aType.ERROR, payload: { loading: false, error: error } });
    }
}

// Export Methods with Create Context
export const { Provider, Context } = createAppContext(
    userReducer,
    { setContextGeolocation, setContextCountry, setContextWelcome, setContextLoadingFilter },
    {
        showWelcome: true,
        geolocation: { data: null, loading: true, error: null },
        loadingFilter: { data: null, loading: true, error: null }
    }
)