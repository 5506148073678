// Import Utils
import aType from "../utils/ActionTypes";

const userReducer = (state, action) => {
    switch (action.type) {
        case aType.SET_GEOLOCATION:
            return {
                ...state,
                geolocation: {
                    data: action.payload.data,
                    loading: action.payload.loading,
                    error: null,
                },
            };
        case aType.SET_WELCOME:
            return {
                ...state,
                showWelcome: action.payload,
            };
        case aType.SET_LOADING_FILTER:
            return {
                ...state,
                loadingFilter: {
                    data: action.payload.data,
                    loading: action.payload.loading,
                    error: null,
                },
            };
        case aType.ERROR:
            return {
                ...state,
                geolocation: {
                    ...state.geolocation,
                    loading: action.payload.loading,
                    error: action.payload.error,
                },
            };
        default:
            return state;
    }
};

export default userReducer;