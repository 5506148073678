// Import Library
import { Fragment, useState, useRef, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';

// import context
import { Context as UserContext } from '../../dataStore/userAccessContext';

// Import Component
import SelectGeneric from "../select/select-generic";

const title = "Filtrar Miembro Búsqueda";
const desc = "Tu cita perfecta está a solo un clic.";
const btnText = "Buscar";

const FilterSearch = () => {

    const { state } = useContext(UserContext);
    const { loadingFilter } = state;
    const navigate = useNavigate();
    const [searchFilter, setSearchFilter] = useState({
        nationality: loadingFilter?.data?.catalogNationality?.[0]?.code || null,
        category: loadingFilter?.data?.catalogCategory?.[0]?.code || null,
        height: loadingFilter?.data?.catalogHeight?.[0]?.code || null,
        ageStart: loadingFilter?.data?.ages?.[0]?.code || null,
        ageEnd: loadingFilter?.data?.ages?.[0]?.code || null,
    });
    const [showAlert, setShowAlert] = useState(false);
    const timerRef = useRef(null);

    const handleSelectChange = (field, value) => {

        setSearchFilter(prevState => ({
            ...prevState,
            [field]: value
        }))

        if (field === 'ageStart' && searchFilter.ageEnd !== null && value > searchFilter.ageEnd) {
            triggerAlert();
        }

        if (field === 'ageEnd' && searchFilter.ageStart !== null && value < searchFilter.ageStart) {
            triggerAlert();
        }
    }

    const triggerAlert = () => {
        setShowAlert(true);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            setShowAlert(false);
        }, config.autoHideDuration);
    }

    const handleButtonClick = (e) => {
        e.preventDefault();
        if (searchFilter.ageStart !== null && searchFilter.ageEnd !== null && searchFilter.ageStart > searchFilter.ageEnd) {
            triggerAlert();
            return;
        }
        navigate('/community', { state: { searchFilter: searchFilter } });
    }

    return (
        <Fragment>
            {loadingFilter.data &&
                Array.isArray(loadingFilter.data.ages) && loadingFilter.data.ages.length > 0 &&
                Array.isArray(loadingFilter.data.catalogCategory) && loadingFilter.data.catalogCategory.length > 0 &&
                Array.isArray(loadingFilter.data.catalogHeight) && loadingFilter.data.catalogHeight.length > 0 &&
                Array.isArray(loadingFilter.data.catalogNationality) && loadingFilter.data.catalogNationality.length > 0 &&
                (
                    <div className="widget search-widget">
                        <div className="widget-inner">
                            <div className="widget-title">
                                <h5>{title}</h5>
                            </div>
                            <div className="widget-content">
                                <p>{desc}</p>
                                <form onSubmit={handleButtonClick} className="banner-form">
                                    <div className="gender">
                                        <div className="custom-select right w-100">
                                            <SelectGeneric
                                                loadingDefault={loadingFilter.data.catalogNationality[0].code}
                                                options={loadingFilter.data.catalogNationality}
                                                onChange={(value) => handleSelectChange('nationality', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="person">
                                        <div className="custom-select right w-100">
                                            <SelectGeneric
                                                loadingDefault={loadingFilter.data.catalogCategory[0].code}
                                                options={loadingFilter.data.catalogCategory}
                                                onChange={(value) => handleSelectChange('category', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="city">
                                        <div className="custom-select right w-100">
                                            <SelectGeneric
                                                loadingDefault={loadingFilter.data.catalogHeight[0].code}
                                                options={loadingFilter.data.catalogHeight}
                                                onChange={(value) => handleSelectChange('height', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="age">
                                        <div
                                            className="right d-flex justify-content-between w-100">
                                            <div className="custom-select">
                                                <SelectGeneric
                                                    loadingDefault={loadingFilter.data.ages[0].code}
                                                    options={loadingFilter.data.ages}
                                                    onChange={(value) => handleSelectChange('ageStart', value)}
                                                />
                                            </div>
                                            <div className="custom-select">
                                                <SelectGeneric
                                                    loadingDefault={loadingFilter.data.ages[loadingFilter.data.ages.length - 1].code}
                                                    options={loadingFilter.data.ages}
                                                    onChange={(value) => handleSelectChange('ageEnd', value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button>{btnText}</button>
                                </form>
                                {showAlert && (
                                    <div className="col-12">
                                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                                            {ageValidationError}
                                        </Alert>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
        </Fragment>
    );
}

export default FilterSearch;