// Import Library
import { useContext, Fragment, useEffect } from "react";
import { differenceInYears } from 'date-fns';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useNavigate } from "react-router-dom";

// import context
import { Context as UserContext } from '../../dataStore/userAccessContext';

// Import Component
import Footer from "../layout/footer";
import HeaderTwo from "../layout/headertwo";
import Pagination from "../layout/pagination";

// Import Styles
import 'swiper/css';
import 'swiper/css/navigation';
import '../../styles/swiperStyles.css';
import { cardStyle, labInnerStyle, labThumbStyle, swiperStyle, swiperSlideStyle, imgAvailableStyle, imgDefaultStyle, slideCounterStyle, labContentStyle, blockStyle, iconStyle, topLabelStyle, multiLineTruncateStyle } from '../../styles/active-groupStyles';

// Import Config
import config from "../../config";

const textWhatsapp = 'Hola, acabo de ver tu anuncio en Belbom y me gustaría contactarme contigo'
const currentDate = new Date();

const title = "¡Ups!";
const desc1 = "Tu búsqueda no ha dado ningún resultado.";
const desc2 = "Selecciona un pais para buscar anuncios"

const CardProfile = ({ profiles, setPageNumber, pageNumber, totalPages }) => {

    const { state } = useContext(UserContext);
    const { geolocation } = state;
    const navigate = useNavigate();

    const getProfilePhoto = (photo) => {
        if (photo && photo.fileName && photo.path) {
            return `${process.env.REACT_APP_URL_MEDIA}${photo.path}/${photo.fileName}`;
        }
        return `${process.env.PUBLIC_URL}/assets/images/logo/fondo_xugars_2.png`;
    }

    const getImgStyle = (photo) => {
        if (photo && photo.fileName && photo.path) {
            return imgAvailableStyle
        }
        return imgDefaultStyle
    }

    const handleCardClick = (value) => {
        navigate('/profile', { state: { profile: value } });
    }

    const handleNavigationStyle = (swiper) => {
        const prevEl = swiper.navigation.prevEl;
        const nextEl = swiper.navigation.nextEl;
        if (prevEl && nextEl) {
            prevEl.addEventListener('click', (e) => e.stopPropagation());
            nextEl.addEventListener('click', (e) => e.stopPropagation());
        }
    }

    useEffect(() => {
        if (!geolocation?.data?.country) {
            navigate('/');
        }
    }, [])

    return (
        <Fragment>
            {geolocation?.data?.country && (
                <HeaderTwo country={geolocation.data.country} />
            )}
            <section className="group-page-section group-section style2 padding-tb">
                <div className="container">
                    {profiles.length > 0 ? (
                        <div className="groups-wrapper">
                            <div className="row g-4 row-cols-1" style={{ paddingTop: '50px' }}>
                                {profiles.map((value, index) => (
                                    <div className="col" key={index}>
                                        <div className="group-item lab-item" style={cardStyle} onClick={() => handleCardClick(value)}>
                                            <div style={{ position: 'relative' }}>
                                                {value.superTop && (
                                                    <span style={topLabelStyle}>🥇 Top</span>
                                                )}
                                                <div className="lab-inner" style={labInnerStyle}>
                                                    <div className="lab-thumb" style={labThumbStyle}>
                                                        {value.superTop && value.photos.length > 1 ? (
                                                            <Swiper
                                                                slidesPerView={1}
                                                                spaceBetween={30}
                                                                loop={true}
                                                                centeredSlides={true}
                                                                navigation={true}
                                                                modules={[Navigation]}
                                                                className="mySwiper"
                                                                style={swiperStyle}
                                                                onSwiper={handleNavigationStyle}
                                                            >
                                                                {value.photos.map((photo, index) => (
                                                                    <SwiperSlide key={index} style={swiperSlideStyle}>
                                                                        <img
                                                                            src={getProfilePhoto(photo)}
                                                                            alt={`${photo.fileName}`}
                                                                            style={getImgStyle(photo)}
                                                                        />
                                                                    </SwiperSlide>
                                                                ))}
                                                            </Swiper>
                                                        ) : (
                                                            <img
                                                                src={getProfilePhoto(value.photos.find(p => p.isProfilePhoto))}
                                                                alt={`${value.fileName}`}
                                                                style={getImgStyle(value.photos.find(p => p.isProfilePhoto))}
                                                            />
                                                        )}
                                                        <span style={slideCounterStyle}>
                                                            <i className="icofont-picture" style={iconStyle}></i>
                                                            {`${value.photos.length}`}
                                                        </span>
                                                    </div>
                                                    <div className="lab-content" style={labContentStyle}>
                                                        <h3>{value.alias}</h3>
                                                        <span style={multiLineTruncateStyle}>{value.presentation}</span>
                                                        <span style={blockStyle}><i className="icofont-user-alt-6" style={iconStyle}></i>{`${differenceInYears(currentDate, value.birthDate)} años`}</span>
                                                        <span style={blockStyle}><i className="icofont-location-pin" style={iconStyle}></i>{value.geographicLocation2Name}</span>
                                                        <span style={blockStyle}><i className="icofont-earth" style={iconStyle}></i>{value.nameNationality}</span>
                                                        {value.superTop && (
                                                            <ul className="lab-content-icon">
                                                                <li onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    window.open(`${config.url.whatsapp}/${value.phoneNumber}?text=${encodeURIComponent(textWhatsapp)}`, '_blank');
                                                                }}>
                                                                    <i className={"icofont-brand-whatsapp"}></i>
                                                                </li>
                                                                <li onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    window.location.href = `tel:${value.phoneNumber}`;
                                                                }}>
                                                                    <i className="icofont-phone"></i>
                                                                </li>
                                                            </ul>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Pagination
                                setPageNumber={setPageNumber}
                                pageNumber={pageNumber}
                                totalPages={totalPages}
                            />
                        </div>
                    ) : (
                        <div className="section-header" style={{ paddingTop: "100px" }}>
                            <h2 >{title}</h2>
                            <p>{desc1}</p>
                            <span>{desc2}</span>
                        </div>
                    )}
                </div>
            </section>
            <style>
                {`
                        @media (max-width: 768px) {
                            .lab-thumb {
                                flex: 0 0 40% !important;
                                minWidth: 120px !important;
                            }

                            .lab-content {
                                flex: 0 0 60% !important;
                                padding-left: 10px !important; 
                            }
                        }
                    `}
            </style>
            <Footer />
        </Fragment>
    )
}

export default CardProfile