// Import Library
import { Fragment, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// import context
import { Context as UserContext } from '../../dataStore/userAccessContext';

const logoStyle = {
    width: '160px',
    height: '50px',
    objectFit: 'cover'
}

const HeaderTwo = () => {

    const { state } = useContext(UserContext);
    const { geolocation } = state;
    const navigate = useNavigate();

    const menuTriggerTwo = () => {
        document.querySelector('.header-top').classList.toggle('open')
    }

    document.addEventListener('DOMContentLoaded', function () {
        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            var headerSection = document.querySelector('.header-section');

            if (headerSection) {
                if (value > 200) {
                    headerSection.classList.add('header-fixed', 'animated', 'fadeInDown');
                } else {
                    headerSection.classList.remove('header-fixed', 'animated', 'fadeInDown');
                }
            }
        });
    })

    useEffect(() => {
        if (!geolocation?.data?.country) {
            navigate('/');
        }
    }, []);

    return (
        <Fragment>
            <header className="header-section">
                <div className="header-top bg-theme">
                    <div className="container">
                        <div className="header-top-area">
                            <ul className="left">
                                <li >
                                    <i className={'icofont-location-pin'}></i>Estas en <span>{geolocation?.data?.country ? geolocation.data.country : 'Cargando...'}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="header-bottom bg-primary">
                    <div className="container">
                        <div className="header-wrapper">
                            <div className="logo">
                                <Link to="/">
                                    <img src="assets/images/logo/logo.png" alt="logo" style={logoStyle} />
                                </Link>
                            </div>
                            <div className="menu-area">
                                <ul className="menu">
                                </ul>
                                <a className="login" href={process.env.REACT_APP_URL_PANEL} target="_blank" rel="noopener noreferrer"><i className="icofont-user"></i> <span>Entra y anunciate</span> </a>
                                <div className="ellepsis-bar d-lg-none" onClick={menuTriggerTwo}>
                                    <i className="icofont-info-square"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </Fragment>
    );
}

export default HeaderTwo;