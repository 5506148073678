// Import Library
import { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";

// Import Component
import LoadingText from "../loading/loadingtext";

// Import Web Api
import posterServices from '../../dataservices/posterServices'

const title = "Ciudades";
const desc = "";

const City = (props) => {

    const { countryCode, country } = props
    const [cityPosters, setCityPosters] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchStartValues = async () => {
        setLoading(true);
        const responseCityPosters = await posterServices.getCities(countryCode);
        setLoading(false);
        if (!responseCityPosters.code) {
            setCityPosters(responseCityPosters)
        }
    }

    useEffect(() => {
        fetchStartValues();
    }, [countryCode])

    return (
        <Fragment>
            {loading && (<LoadingText />)}
            <div className="meet padding-tb">
                <div className="container">
                    <div className="section-header">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="section__wrapper">
                        <div className="row g-4 justify-content-center">
                            {cityPosters.map((value, index) => (
                                <div className="col-lg-3 col-sm-6 col-12" key={index}>
                                    <div className="meet__item">
                                        <div className="meet__inner">
                                            <div className="meet__thumb">
                                                <Link
                                                    to="/active-group"
                                                    state={{ countryCode: countryCode, geographicLocation2Code: value.geographicLocation2Code }}>
                                                    <img
                                                        src={`${value.path}/${value.fileName}`}
                                                        alt={`${value.fileName}`}
                                                    />
                                                </Link>
                                            </div>
                                            <div className="meet__content">
                                                <h4>{value.name}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default City;